<template>
  <base-section
    id="login"
    space="56"
  >
    <v-container>
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="8"
        >
          <v-card class="evelation-12 card">
            <v-window v-model="step">
              <!-- Sign In -->
              <v-window-item :value="1">
                <v-row class="">
                  <v-col
                    cols="12"
                    md="8"
                    class="pt-6 pb-6"
                  >
                    <v-card-text>
                      <validation-observer v-slot="{ handleSubmit }">
                        <v-form
                          class="signup-form-form"
                          @submit.prevent="handleSubmit(signIn)"
                        >
                          <base-section-heading :title="$t('register.sign-in')" />
                          <!-- <validation-provider
                            v-slot="{ errors }"
                            :name="$t('common.email')"
                            rules="required|email"
                          > -->
                          <validation-provider
                            v-slot="{ errors }"
                            :name="`${$t('common.email')} / ${$t('register.user-name')}`"
                            rules="required"
                          >
                            <v-text-field
                              v-model="username"
                              :error-messages="errors"
                              :color="bgColor"
                              :label="`${$t('common.email')} / ${$t('register.user-name')}`"
                              prepend-icon="mdi-email"
                            />
                          </validation-provider>

                          <validation-provider
                            v-slot="{ errors }"
                            name="Password"
                            rules="required"
                          >
                            <v-text-field
                              v-model="password"
                              :error-messages="errors"
                              color="secondary"
                              :label="`${$t('register.password')}...`"
                              prepend-icon="mdi-lock-outline"
                              type="password"
                            />
                          </validation-provider>

                          <div class="text-center">
                            <a
                              href="#"
                              class="mt-8 overline no-text-decoration"
                              :class="`${bgColor}--text`"
                              @click="step = 3"
                            >
                              {{ $t('register.forgotpassword') }}
                            </a>
                          </div>

                          <vue-recaptcha
                            ref="signinRecaptcha"
                            :sitekey="sitekey"
                            size="invisible"
                            @verify="onVerify"
                            @expired="onExpired"
                          />
                          <div class="text-center  mt-6">
                            <pages-btn
                              large
                              color=""
                              depressed
                              class="v-btn--text success--text"
                              type="submit"
                            >
                              {{ $t('register.btn-start') }}
                            </pages-btn>
                          </div>
                        </v-form>
                      </validation-observer>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="darken-2 vcenter"
                    :class="`${bgColor}`"
                  >
                    <div>
                      <v-card-text :class="`${fgColor}--text`">
                        <h1 class="text-center headline mb-3">
                          {{ $t('register.nouser') }}
                        </h1>
                        <h5 class="text-center overline mb-3">
                          {{ $t('register.pleasesignup') }}
                        </h5>
                      </v-card-text>
                      <div class="text-center mb-6">
                        <v-btn
                          dark
                          outlined
                          @click="step = 2"
                        >
                          {{ $t('register.sign-up') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>

              <!-- Sign Up -->
              <v-window-item :value="2">
                <v-row class="fill-height">
                  <v-col
                    cols="12"
                    md="4"
                    class="darken-2 vcenter"
                    :class="`${bgColor}`"
                  >
                    <div>
                      <v-card-text :class="`${fgColor}--text`">
                        <h1 class="text-center headline mb-3">
                          {{ $t('register.alreadyuser') }}
                        </h1>
                        <h5 class="text-center overline mb-3">
                          {{ $t('register.pleasesignin') }}
                        </h5>
                      </v-card-text>
                      <div class="text-center mb-6">
                        <v-btn
                          dark
                          outlined
                          @click="step = 1"
                        >
                          {{ $t('register.sign-in') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                    class=" pt-6 pb-6"
                  >
                    <v-card-text>
                      <base-section-heading :title="$t('register.sign-up')" />
                      <validation-observer v-slot="{ handleSubmit }">
                        <v-form
                          class="signup-form-form"
                          @submit.prevent="handleSubmit(signUp)"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('common.email')"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="email"
                              :error-messages="errors"
                              color="secondary"
                              :label="`${$t('common.email')}...`"
                              prepend-icon="mdi-email"
                            />
                          </validation-provider>

                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('register.user-name')"
                            :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/, max:40 }"
                          >
                            <v-text-field
                              v-model="username"
                              :error-messages="errors"
                              color="secondary"
                              :label="`${$t('register.user-name')}...`"
                              prepend-icon="mdi-account"
                              counter="25"
                              hint="max 25 alphabetic characters, numbers or space"
                            />
                          </validation-provider>

                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('register.password')"
                            rules="required"
                          >
                            <v-text-field
                              v-model="password"
                              :error-messages="errors"
                              color="secondary"
                              :label="`${$t('register.password')}...`"
                              prepend-icon="mdi-lock-outline"
                              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show ? 'text' : 'password'"
                              @click:append.prevent="show = !show"
                            />
                          </validation-provider>

                          <!--<validation-provider
                            v-slot="{ errors }"
                            name="Organization's name"
                            :rules="{ required: true, regex: /^[a-zA-Z0-9\s]+$/, max:40 }"
                          >
                            <v-text-field
                              v-model="orgName"
                              :error-messages="errors"
                              label="Name of Organization"
                              prepend-icon="mdi-domain"
                              counter="40"
                              hint="max 40 alphabetic characters, numbers or space"
                            />
                          </validation-provider>-->

                          <vue-recaptcha
                            ref="signupRecaptcha"
                            :sitekey="sitekey"
                            size="invisible"
                            @verify="onVerify"
                            @expired="onExpired"
                          />
                          <div class="text-center mt-6">
                            <pages-btn
                              color="success"
                              type="submit"
                            >
                              {{ $t('register.btn-signup') }}
                            </pages-btn>
                          </div>
                        </v-form>
                      </validation-observer>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>

              <!--PW Rest-->
              <v-window-item :value="3">
                <v-row class="fill-height">
                  <v-col
                    cols="12"
                    md="4"
                    class="darken-2 vcenter"
                    :class="`${bgColor}`"
                  >
                    <div>
                      <v-card-text :class="`${fgColor}--text`">
                        <h1 class="text-center headline mb-3">
                          {{ $t('register.alreadyuser') }}
                        </h1>
                        <h5 class="text-center overline mb-3">
                          {{ $t('register.pleasesignin') }}
                        </h5>
                      </v-card-text>
                      <div class="text-center mb-6">
                        <v-btn
                          dark
                          outlined
                          @click="step = 1"
                        >
                          {{ $t('register.sign-in') }}
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                    class="pt-6 pb-6"
                  >
                    <v-card-text>
                      <base-section-heading :title="$t('register.reset-password')" />
                      <validation-observer v-slot="{ handleSubmit }">
                        <v-form
                          class="reset-form-form"
                          @submit.prevent="handleSubmit(resetpassw)"
                        >
                          <vue-recaptcha
                            ref="resetpasswRecaptcha"
                            :sitekey="sitekey"
                            size="invisible"
                            @verify="onVerify"
                            @expired="onExpired"
                          />
                          <v-switch
                            v-model="resetCode"
                            color="success"
                            :label="$t('register.have-reset-code')"
                          />

                          <validation-provider
                            v-slot="{ errors }"
                            :name="$t('common.email')"
                            rules="required|email"
                          >
                            <v-text-field
                              v-model="email"
                              outlined
                              dense
                              :error-messages="errors"
                              color="secondary"
                              :label="$t('register.email')"
                              prepend-icon="mdi-email"
                            />
                          </validation-provider>

                          <validation-provider
                            v-if="resetCode"
                            v-slot="{ errors }"
                            :name="$t('register.reset-code')"
                            rules="required"
                          >
                            <v-text-field
                              v-model="resetPassword"
                              outlined
                              dense
                              :error-messages="errors"
                              :label="$t('register.reset-code')"
                              prepend-icon="mdi-account-key"
                            />
                          </validation-provider>

                          <validation-provider
                            v-if="resetCode"
                            v-slot="{ errors }"
                            :name="$t('register.new-password')"
                            rules="required"
                          >
                            <v-text-field
                              v-model="newPassword"
                              outlined
                              dense
                              :error-messages="errors"
                              color="secondary"
                              :label="$t('register.new-password')"
                              prepend-icon="mdi-lock-outline"
                              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPass ? 'text' : 'password'"
                              @click:append.prevent="showPass = !showPass"
                            />
                          </validation-provider>

                          <div class="text-center mt-6">
                            <pages-btn
                              v-if="!resetCode"
                              color="success"
                              type="submit"
                            >
                              {{ $t('register.request-code') }}
                            </pages-btn>
                            <pages-btn
                              v-else
                              color="success"
                              type="submit"
                            >
                              {{ $t('register.btn-reset') }}
                            </pages-btn>
                          </div>
                        </v-form>
                      </validation-observer>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- New SnackBarCard -->
    <snack-bar-card :snackbar="snackBar" />
  </base-section>
</template>

<script>
  // import Notification from './Notification'
  import { extend } from 'vee-validate'
  // eslint-disable-next-line camelcase
  import { alpha_num, regex } from 'vee-validate/dist/rules'
  import SnackBarCard from '@/components/SnackbarCard'
  import organization from '@/mixins/organization'
  import { VueRecaptcha } from 'vue-recaptcha'

  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  const subscription = useSubscriptionStore()

  // Vee Validate.
  extend('alpha_num', alpha_num)
  extend('regex', regex)

  export default {
    name: 'SectionLogin',

    components: {
      SnackBarCard,
      PagesBtn: () => import('@/components/Btn'),
      'vue-recaptcha': VueRecaptcha,
      // PagesHeading: () => import('@/components/Heading'),
    },

    mixins: [
      organization,
    ],

    props: {
      source: {
        type: String,
        default: '',
      },
      bgColor: {
        type: String,
        default: 'success',
      },
      fgColor: {
        type: String,
        default: 'white',
      },
    },

    async fetch ({ store, error }, user) {
      try {
        await store.dispatch('users/signupUser', user)
      } catch (e) {
        error({
          statusCode: 503,
          message: 'Unable to sign up user. Please try again later.',
        })
      }
    },

    data: () => ({
      step: 1,
      username: '',
      email: '',
      password: '',
      resetCode: true,
      resetPassword: '',
      newPassword: '',
      showPass: false,
      // orgName: '',
      // orgDescription: '...',
      login: '',
      required: '',
      show: false,

      // New snackbar notification
      snackBar: {
        type: 'success',
        mode: 'multi-line',
        direction: 'top center',
        timeout: 3000,
        title: '',
        text: '',
        visible: false,
      },
      sitekey: '6LedcmAiAAAAAG9K7zpVzGmdAlaiutqcWNcj9SKj',
    }),

    methods: {

      signUp (scope) {
        // console.log(subscription.code)
        // TODO require user to confirm his/her email https://drupal.stackexchange.com/a/300320
        const subsType = Object.keys(subscription.aSub).length === 0 ? 'standard' : subscription.aSub.code
        // const subsType = 'standardd'
        this.$refs.signupRecaptcha.execute()
        this.$store
          .dispatch('auth/registerUser', { name: this.username, mail: this.email, pass: this.password, subs: subsType })
          .then(response => {
            // console.log(response)
            if (response === 200) {
              this.snackBar = {
                type: 'success',
                mode: 'multi-line',
                title: `${this.$t('common.success')}`,
                text: this.$t('register.success_note'),
                visible: true,
              }
              const that = this
              setTimeout(function () {
                that.username = ''
                that.email = ''
                that.password = ''
                that.$router.push('/')
              }, 1000)
            } else {
              this.snackBar = {
                type: 'error',
                mode: 'multi-line',
                timeout: 9000,
                title: `${this.$t('common.error')}`,
                text: response,
                visible: true,
              }
            }
          })
      },

      resetpassw () {
        this.$refs.resetpasswRecaptcha.execute()
        const prop = {
          type: this.resetCode,
          email: this.email,
          code: this.resetPassword,
          newpass: this.newPassword,
        }
        this.$store.dispatch('auth/resetpassword', prop)
          .then((response) => {
            this.snackBar = {
              type: 'success',
              mode: 'multi-line',
              title: `${this.$t('common.success')}`,
              text: response.data.message,
              visible: true,
            }
            this.resetCode = true
          })
          .catch((error) => {
            // console.log(error)
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 9000,
              title: `${this.$t('common.error')}`,
              text: error.data.message,
              visible: true,
            }
          })
      },

      async signIn (scope) {
        this.$refs.signinRecaptcha.execute()
        const formData = new FormData()
        formData.set('grant_type', 'password')
        formData.set('client_id', this.$store.state.auth.client_uuid)
        formData.set('username', this.username)
        formData.set('password', this.password)
        this.$store.commit('auth/userProfile', { username: this.username })
        this.$store.commit('auth/userProfile', { fd: formData })
        // const username = this.username
        // const password = this.password
        try {
          await this.$store
            .dispatch('auth/login')
            .then(response => {
              // console.log(response)
              // this.getSubscription()
              if (response.status === 200) {
                this.$router.push({ name: 'Active Forms' })
              }
            })
        } catch (error) {
          // console.log(error)
          // console.log(error.message)
          if (error.message === 'Request failed with status code 401') {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 9000,
              title: `${this.$t('common.error')}`,
              text: 'The user credentials were incorrect.',
              visible: true,
            }
          } else {
            this.snackBar = {
              type: 'error',
              mode: 'multi-line',
              timeout: 9000,
              title: `${this.$t('common.error')}`,
              text: 'There are some error, please, try later',
              visible: true,
            }
          }
        }
      },

      // reCaptcha
      onSubmit: function () {
        this.$refs.invisibleRecaptcha.execute()
      },
      onVerify: function (response) {
        // console.log('Verify: ' + response)
      },
      onExpired: function () {
        // console.log('Expired')
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset() // Direct call reset method
      },
    },
  }
</script>

<style scoped lang="scss">
  .v-input__icon--double .v-input__icon {
    margin-left: -4.25rem !important;
  }
  a.no-text-decoration {
    text-decoration: none;
  }
  #signinup-form {
    max-width: 75rem;
  }
  .signup-form-form {
    max-width: 23rem;
    margin: 0 auto;
  }
  .card {
    overflow: hidden;
  }
  .vcenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
